.custom-toolbar-container {
  display: flex;
  z-index: 999;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 30px;

  @media (max-width: 768px) {
    padding-bottom: 16px;
  }
  
  .rbc-toolbar-label {
    border-radius: 8px;
    background: rgba(51, 168, 237, 0.20);
    padding: 10px;
    color: #000;
    font-family: "Noto Sans JP"!important;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: -0.22px;
    min-width: 210px;

    @media (max-width: 768px) {
      padding: 8px;
      font-size: 16px;
      letter-spacing: -0.176px;
      min-width: 186px;
    }
  }

  .arrow-style {
    width: 44px;
    height: 44px;
    cursor: pointer;

    @media (max-width: 768px) {
      height: 32px;
      width: 32px;
    }
  }
}

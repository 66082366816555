@media (max-width: 768px) {
  .BaseTable__table .BaseTable__body .BaseTable__row {
    border: 1px solid #eeeeee;
  }
}
@media (max-width: 768px) {
  .BaseTable__table .BaseTable__body .BaseTable__row-cell {
    font-size: 10px;
    padding-right: 0px;
    padding-left: 5px;
  }
}
.BaseTable__table .BaseTable__body::-webkit-scrollbar {
  -webkit-appearance: none;
}
.BaseTable__table .BaseTable__body::-webkit-scrollbar:vertical {
  width: 10px;
}
.BaseTable__table .BaseTable__body::-webkit-scrollbar:horizontal {
  height: 10px;
}
.BaseTable__table .BaseTable__body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  border: 2px solid #e3e3e3;
  background-color: #999;
}
.BaseTable__table .BaseTable__body::-webkit-scrollbar-thumb:hover {
  background-color: #666;
}
.BaseTable__table .BaseTable__body::-webkit-resizer {
  display: none;
}

.interview-times-container {
  margin: 140px auto;
  margin-left: 220px;
}
@media (max-width: 768px) {
  .interview-times-container {
    overflow-x: hidden;
    margin-left: 0px;
  }
}
.interview-times-container .inner-container {
  width: 100%;
  display: block;
  margin-top: 100px;
}
@media (max-width: 1024px) {
  .interview-times-container .inner-container {
    margin-top: 50px;
  }
}
.interview-times-container .inner-container .sort-select {
  width: 350px;
  margin: 20px auto;
}
.interview-times-container .inner-container .job-select-container {
  display: block;
  width: 500px;
  margin: 10px auto;
}
.interview-times-container .inner-container .job-select-container select {
  width: 100%;
}
@media (max-width: 768px) {
  .interview-times-container .inner-container .job-select-container {
    width: 350px;
  }
}
.interview-times-container .inner-container .calendar-container {
  width: 1000px;
  overflow-x: scroll;
  margin: 0px auto;
  padding: 10px;
}
@media (max-width: 768px) {
  .interview-times-container .inner-container .calendar-container .desktop-calendar {
    display: none;
  }
}
.interview-times-container .inner-container .calendar-container .mobile-calendar {
  display: none;
  font-size: 12px;
}
@media (max-width: 768px) {
  .interview-times-container .inner-container .calendar-container .mobile-calendar {
    display: block;
  }
}
@media (max-width: 768px) {
  .interview-times-container .inner-container .calendar-container {
    width: 350px;
    max-width: 350px;
    min-width: 350px;
    padding: 0px;
    margin: 20px auto;
  }
}
.interview-times-container .inner-container .calendar-container .no-data-text {
  width: 500px;
  margin: 20px auto;
}
.interview-times-container .inner-container .calendar-container .table-parent {
  width: 1000px;
  margin: 0px auto;
  max-height: 100px;
  border: 1px solid #e0e0e0;
}
.interview-times-container .inner-container .calendar-container .table-parent .table-head {
  position: sticky !important;
  left: 0;
  top: 0;
  z-index: 99;
  background-color: white;
  min-width: 250px !important;
}
@media (max-width: 768px) {
  .interview-times-container .inner-container .calendar-container .table-parent .table-head {
    min-width: 90px !important;
  }
}
.interview-times-container .inner-container .calendar-container .table-parent .table-body {
  max-height: 75% !important;
}
.interview-times-container .inner-container .calendar-container .table-parent .table-body .column {
  padding: 10px;
}
.interview-times-container .inner-container .calendar-container .table-parent .column {
  padding: 10px;
}

.rbc-calendar .rbc-time-header-content {
  margin-right: 9px;
}
.rbc-calendar .rbc-time-content::-webkit-scrollbar {
  width: 10px;
  -webkit-appearance: none;
}
.rbc-calendar .rbc-time-content::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white; /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, 0.5);
}
.rbc-calendar .rbc-time-content::-webkit-scrollbar:horizontal {
  height: 10px;
}
.rbc-calendar .rbc-time-content::-webkit-scrollbar:vertical {
  height: 10px;
}